
import CustomInput from "@/components/CustomInput.vue";
import CommandTemplate from "./CommandTemplate.vue";
import CustomButton from "@/components/CustomButton.vue";
import InformationTemplate from "@/components/InformationTemplate.vue";
import { updateBot } from "@/services/botService";
import User from "@/interfaces/User";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Account",
  components: {
    CustomButton,
    CustomInput,
    InformationTemplate,
    CommandTemplate,
  },
  props: {
    botCommands: {
      type: Array as PropType<any[]>,
      required: true,
    },
    botPrefix: {
      type: String,
      required: true,
    },
    bot: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      error: null as null | string,
      requestSent: false,
      localBotPrefix: this.botPrefix,
      localBotCommands: this.botCommands.map((c) => {
        return { ...c, id: Math.random() };
      }),
      showSaveButton: false,
    };
  },
  watch: {
    localBotPrefix: {
      handler: "onPrefixChange",
    },
  },
  methods: {
    commandChange(index: number, data: any) {
      this.showSaveButton = true;
      this.localBotCommands[index] = data;
    },
    deleteCommand(index: number) {
      this.showSaveButton = true;
      this.localBotCommands.splice(index, 1);
    },
    addButton() {
      this.showSaveButton = true;

      this.localBotCommands.unshift({
        c: "ban",
        a: "[@user][reason]//Ban A Member",
        id: Math.random(),
      });
    },
    saveButton() {
      if (this.requestSent) return;
      this.requestSent = true;
      this.error = null;
      const commands = this.localBotCommands.map((cmd) => {
        return {
          c: cmd.c,
          a: cmd.a,
        };
      });
      updateBot(this.bot.id, {
        botPrefix: this.localBotPrefix,
        botCommands: commands,
      })
        .then(() => {
          this.showSaveButton = false;
          this.$emit("updated", {
            botPrefix: this.localBotPrefix,
            botCommands: commands,
          });
        })
        .catch(async (err) => {
          if (!err.response) {
            this.error = this.$t("could-not-connect-to-server").toString();
            return;
          }
          const { errors, message } = await err.response.json();
          if (message) {
            this.error = message;
            return;
          }
          if (errors.length) {
            this.error = errors[0].msg;
          }
        })
        .finally(() => (this.requestSent = false));
    },
    onPrefixChange() {
      this.showSaveButton = true;
    },
  },
});
