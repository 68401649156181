
import CustomButton from "@/components/CustomButton.vue";
import SelectedBotPage from "./SelectedBotPage.vue";
import BotTemplate from "./BotTemplate.vue";
import User from "@/interfaces/User";
import { createBot, getBots } from "@/services/botService";
import { PopoutsModule } from "@/store/modules/popouts";

import { defineComponent } from "vue";
export default defineComponent({
  name: "ManageChannels",
  components: {
    CustomButton,
    SelectedBotPage,
    BotTemplate,
  },
  data() {
    return {
      showContext: false,
      selectedBotIndex: -1,
      createRequestSent: false,
      bots: null as User[] | null,
    };
  },
  mounted() {
    getBots().then((bot) => {
      this.bots = bot.reverse();
    });
  },
  methods: {
    botUpdated(data) {
      if (!this.bots) return;
      const bot = this.bots[this.selectedBotIndex];
      if (!bot) return;
      this.bots[this.selectedBotIndex] = { ...bot, ...data };
    },
    botDeleted() {
      if (!this.bots) return;
      this.bots.splice(this.selectedBotIndex, 1);
      this.selectedBotIndex = -1;
    },
    createBot() {
      if (this.createRequestSent) return;
      this.createRequestSent = true;
      createBot()
        .then((bot) => {
          this.bots?.unshift(bot);
          this.selectedBotIndex = 0;
        })
        .catch(async (err) => {
          PopoutsModule.ShowPopout({
            id: "error",
            component: "generic-popout",
            data: {
              title: "Error Creating Bot",
              description: !err.response
                ? this.$t("could-not-connect-to-server")
                : (await err.response.json()).message,
            },
          });
        })
        .finally(() => (this.createRequestSent = false));
    },
  },
});
