
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["change", "delete"],

  name: "CommandTemplate",
  props: {
    command: {
      type: Object as PropType<{ a: string; c: string; id: string }>,
      required: true,
    },
  },
  data() {
    return {
      localCommand: this.command.c,
      localAction: this.command.a,
    };
  },
  watch: {
    localCommand: {
      handler: "commandChange",
    },
    localAction: {
      handler: "actionChange",
    },
  },
  methods: {
    commandChange() {
      this.$emit("change", {
        a: this.localAction,
        c: this.localCommand,
        id: this.command.id,
      });
    },
    actionChange() {
      this.$emit("change", {
        a: this.localAction,
        c: this.localCommand,
        id: this.command.id,
      });
    },
  },
});
